import {IItem} from "../../../interfaces/item.interface";
import {useDeleteButton} from "../../../states/items/deletebutton.state";
import {Button} from "reactstrap";

export const DeleteItem = ({item}:{item:IItem}) => {
    const {
        handleClick
    } = useDeleteButton({item: item});

    return (
        <Button color={"danger"} onClick={handleClick}>Delete</Button>
    )
}