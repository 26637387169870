import {DefaultTemplate} from "../components/templates/default.template";
import {Button, Col} from "reactstrap";
import {ItemEditProvider} from "../contexts/item/item.context";
import {useItemFull} from "../states/items/itemfull.state";
import {ItemEditForm} from "../components/items/organisms/itemedit.organism";
import {Loading} from "../components/common/molecules/loading.molecule";
import {BsCaretLeft} from "react-icons/all";
import {DeleteItem} from "../components/items/atoms/deleteitem.atom";

export const ItemEdit = (props:any) => {
    const {
        item,
        loading,
    } = useItemFull(props.match.params.id);

    const content = (loading)? (<Loading/>): (item) ? (
        <Col className={"mx-auto"} md={8}>
            <Button color={"link"} href={"/item/"+item.id}><BsCaretLeft/> Return to Item</Button>
            <ItemEditProvider item={item}>
                <ItemEditForm/>
                <DeleteItem item={item}/>
            </ItemEditProvider>
        </Col>
    ) : (
        <>No item found</>
    )

    return (
        <DefaultTemplate content={content} footer={''}/>
    )
}