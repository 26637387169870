import {Col, Container, Row, Table} from "reactstrap";
import {IItemEntry} from "../../../interfaces/itementry.interface";
import {AdditionalFields} from "./additionalfields.molecule";

export const AdditionalData = ({item_entries}:{item_entries:IItemEntry[]}) => {
    return (
        <Container>
            <Row>
                <Col>
                    <Table>
                        <thead>
                        <tr>
                            <th colSpan={2}>Specifications:</th>
                        </tr>
                        </thead>
                        <tbody>
                        {item_entries.map(entry => {
                            return <AdditionalFields key={entry.form_name} values={entry.data.values} fields={entry.data.fields}/>
                        })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}