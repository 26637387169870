import {useCallback, useEffect, useState} from "react";
import {IItem} from "../../interfaces/item.interface";
import {useAuthState} from "../../contexts/auth/context";
import Api, {authHeaders} from "../../modules/api.module";
import {useItemFilterContext} from "../../contexts/filter/filter.context";

export const useItemListing = () => {
    const [items, setItems] = useState<IItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const userDetails = useAuthState();
    const itemFilter = useItemFilterContext();
    const [filteredItems, setFilteredItems] = useState<IItem[]>(items);

    const getItems = useCallback(() => {
        if(!userDetails.auth_token) return;
        setLoading(true);
        Api
            .get('get_items',
                authHeaders(userDetails.auth_token)
            )
            .then(
                res=>{
                    setItems(res.data);
                }
            )
            .catch(
                err=>{
                    setItems([]);
                }
            )
            .finally(()=>{
                setLoading(false);
            })
    }, [userDetails.auth_token]);
    const runFilter = useCallback(() => {
        if(items.length === 0) {
            return;
        }
        let currFiltered = [...items];
        setLoading(true);
        if(typeof itemFilter.item_type !== "undefined" && itemFilter.item_type.id > -1) {
            currFiltered = currFiltered.filter((item) => (item.item_type.id === itemFilter.item_type?.id));
        }
        if(typeof itemFilter.brand !== "undefined" && itemFilter.brand.id > -1) {
            currFiltered = currFiltered.filter(item => (item.brand.id === itemFilter.brand?.id))
        }
        if(typeof itemFilter.name !== "undefined" && itemFilter.name.length > 1) {
            currFiltered = currFiltered.filter(item => (item.name.includes(itemFilter.name as string)))
        }
        if(typeof itemFilter.meta_keys !== "undefined") {
            Object.entries(itemFilter.meta_keys).forEach(meta => {
                currFiltered = currFiltered.filter(item => {
                    return (item.item_entries?.find(entry => {
                        if(meta[0] in entry.data.values) {
                            return entry.data.values[meta[0]].includes(meta[1])
                        }
                        return false
                    }))
                })
            })
        }
        setFilteredItems(currFiltered);
        setLoading(false);
        return currFiltered;
    }, [itemFilter, items]);

    useEffect(()=>{
        getItems()
    }, [getItems])
    useEffect(()=>{
        runFilter()
    },[items, runFilter])

    return {
        items,
        loading,
        filteredItems,
    }
}