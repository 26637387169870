import {useItemListing} from "../../../states/items/listing.state";
import {ItemRow} from "../molecules/itemrow.molecule";
import {IItemTableColumn} from "../../../interfaces/itemtablecolumn";
import {Loading} from "../../common/molecules/loading.molecule";

export const ItemRows = ({columns}:{columns:IItemTableColumn[]}) => {
    const {
        loading,
        filteredItems
    } = useItemListing();

    if(loading) {
        return (
            <tr>
                <td colSpan={100}><Loading/></td>
            </tr>
        )
    }

    return (
        <>
            {filteredItems.map(item => {
                return <ItemRow key={item.id} item={item} columns={columns}/>
            })}
        </>
    )
}