import {IRoute} from "../interfaces/route.interface";
import {NotFoundPage} from "../pages/404.page";
import {Redirect} from "react-router-dom";

export const ErrorsRoutes:IRoute[] = [
    {
        path: '/404',
        component: NotFoundPage,
    },
    {
        path: '*',
        component: () => <Redirect to={"/404"}/>
    }
]