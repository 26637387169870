import {useState} from "react";
import {useAuthDispatch, useAuthState} from "../../contexts/auth/context";
import {loginUser} from "../../contexts/auth/actions";
import { useHistory } from "react-router-dom";

export const useLoginFormState = (postSubmit?:()=>void) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {loading, error_message} = useAuthState();
    const dispatch = useAuthDispatch();
    const history = useHistory();

    const usernameChange = (e:any) => setUsername(e.target.value)
    const passwordChange = (e:any) => setPassword(e.target.value)

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        try {
            await loginUser(dispatch, {username, password})
                .then(res => {
                    if(!res || !res.user) return;
                    if(typeof postSubmit !== "undefined") postSubmit()
                    history.push('/');
                })
                .catch(err => {
                    return
                })
        } catch(e) {
        }
    }

    return {
        username,
        usernameChange,
        passwordChange,
        handleSubmit,
        loading,
        error_message
    }
}