import {useCollapseState} from "../../../states/components/collapse.state";
import {useAuthState} from "../../../contexts/auth/context";
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {LoginModal} from "../../account/organisms/loginmodal.organism";

export const MainNavigation = () => {
    const {
        collapsed,
        toggle
    } = useCollapseState()
    const userDetails = useAuthState();

    return (
        <div className={"fixed-top bg-info header"}>
            <Navbar color={"faded"} className={"navbar-expand-lg"} light>
                <NavbarBrand href={"/"} className={"mr-auto text-white"}>HDD-Stock.com</NavbarBrand>
                <NavbarToggler onClick={toggle} className={"mr-2 text-white bg-white"}/>
                <Collapse isOpen={!collapsed} navbar>
                    <Nav navbar>
                        <NavItem>
                            <NavLink href={"/"} className={"text-white"}>Home</NavLink>
                        </NavItem>
                        {userDetails.auth_token && <NavItem><NavLink href={"item/new/"} className={"text-white"}>New Item</NavLink></NavItem>}
                        <NavItem>
                            <NavLink>
                                <LoginModal user={userDetails.user}/>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    )
}