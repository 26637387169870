import {IItem} from "../../../interfaces/item.interface";
import {Spinner, UncontrolledTooltip} from "reactstrap";
import {useWooCommerceIcon} from "../../../states/items/woocommerceicon.state";
import {Loading} from "../../common/molecules/loading.molecule";

export const WooIcon = ({item}:{item:IItem}) => {
    const {
        tooltipText,
        icon,
        action,
        thisItem,
        woostateLoading
    } = useWooCommerceIcon(item);

    if (woostateLoading) {
        return (
            <>
                <Spinner size={"sm"} children={''}/>
            </>
        );
    }

    return (
        <>
            <span className={"d-none"}>{tooltipText}</span>
            <span onClick={action} id={"statusicon"+thisItem.id}>{icon}</span>
            <UncontrolledTooltip target={"statusicon"+thisItem.id} delay={{show:0, hide:10}}>{tooltipText}</UncontrolledTooltip>
        </>
    )
}