import {IAdditionalForm} from "../../../interfaces/additionalForm.interface";
import {useAdditionalForm} from "../../../states/items/additionalform.state";
import {AdditionalFormField} from "../molecules/additionalformfield.molecule";

export const AdditionalForm = ({form, submissionSignal, validationSignal}:{form:IAdditionalForm, submissionSignal?:Date, validationSignal?:Date}) => {
    const {
        fields,
        changeValue
    } = useAdditionalForm({form:form, submissionSignal: submissionSignal, validationSignal:validationSignal});

    return (
        <>
            {fields.map((field) => {
                return <AdditionalFormField key={field.name} field={field} changeFunc={changeValue}/>
            })}
        </>
    )
}