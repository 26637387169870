import {FormFeedback, Input, Label} from "reactstrap";
import {SyntheticEvent} from "react";

type myType = {
    labelName: string
    inputName: string
    changeFunction: (e:SyntheticEvent) => void
    currentValue: number
    options: {id:number, name:string}[]
    formError?: string
}

export const ItemSelectField = ({
    labelName = "",
    inputName = "itemSelectField",
    changeFunction = (e)=>{},
    currentValue = -1,
    options = [],
    formError = ""
}:myType) => {

    return (
        <>
            <Label>{labelName}</Label>
            <Input type={"select"} name={inputName} onChange={changeFunction} value={currentValue} invalid={(formError !== "")}>
                {options.map(option => {
                    return <option key={option.id} value={option.id}>{option.name}</option>
                })}
            </Input>
            {formError !== "" && <FormFeedback>{formError}</FormFeedback>}
        </>
    )
}