import {useEffect, useState} from "react";
import {ISingleItem} from "../../interfaces/item.interface";
import Api, {authHeaders} from "../../modules/api.module";
import {useAuthState} from "../../contexts/auth/context";

export const useItemFull = (id?:number) => {
    const [item, setItem] = useState<ISingleItem>();
    const [loading, setLoading] = useState<boolean>(false);
    const userDetails = useAuthState();

    const getReference = (typeName:string, id:number) => {
        let ref:string;
        switch (typeName) {
            case "Donor Hard Drive":
                ref = "HD"+id;
                break;
            case "PCB":
                ref = "P"+id;
                break;
            case "Bad Sector HDD":
                ref = "B"+id;
                break;
            case "Refurb HDD":
                ref = "R"+id;
                break;
            case "Hard Drive":
                ref = "HD"+id;
                break;
            default:
                ref = id.toString();
                break;
        }
        return ref;
    }

    useEffect(() => {
        if(!userDetails.auth_token) return
        if(!id) return;
        setLoading(true)
        Api
            .get('get_items/'+id, authHeaders(userDetails.auth_token))
            .then(res => {
                res.data.reference = getReference(res.data.item_type.name, res.data.id)
                setItem(res.data);
            })
            .catch(err => {
            })
            .finally(()=>{
                setLoading(false)
            })
    }, [id, userDetails.auth_token])

    return {
        item,
        loading,
        userDetails
    }
}