import {Button, Col} from "reactstrap";
import {ItemEditProvider} from "../contexts/item/item.context";
import {ItemEditForm} from "../components/items/organisms/itemedit.organism";
import {initialState} from "../contexts/item/item.reducer";
import {DefaultTemplate} from "../components/templates/default.template";

export const ItemNew = () => {
    const content = (
        <Col className={"mx-auto"} md={8}>
            <ItemEditProvider item={initialState}>
                <ItemEditForm/>
            </ItemEditProvider>
        </Col>
    )

    return (
        <DefaultTemplate content={content} footer={''}/>
    )
}