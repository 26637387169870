import {FormGroup, Input, Label} from "reactstrap";
import {useNameGeneration} from "../../../states/items/namegeneration.state";
import {GeneratedNameButton} from "../atoms/generatednamebutton.atom";

export const NameInput = () => {
    const {
        changeGeneratingName,
        useGeneratedName,
        changeName,
        item,
        canGenerateName
    } = useNameGeneration();

    return (
        <FormGroup>
            <Label>Name:</Label>
            <GeneratedNameButton useGenerated={useGeneratedName} changeGenerated={changeGeneratingName} disabled={!canGenerateName}/>
            <Input type={"text"} name={"name"} value={item.name} onChange={changeName} disabled={useGeneratedName}/>
        </FormGroup>
    )
}