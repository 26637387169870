import {useAuthState} from "../../contexts/auth/context";
import {Route, Redirect} from 'react-router-dom';

export const ProtectedRoute = ({authentication_path, permissions_required, ...routeProps}:{authentication_path?:string, permissions_required?:[]}) => {
    const user = useAuthState();
    const canViewRoute = () => {
        return Boolean(user.auth_token);
    }
    if(canViewRoute()) {
        return <Route {...routeProps}/>;
    }
    return <Redirect to={{pathname:authentication_path}}/>
}