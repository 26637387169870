import {logout} from "../../contexts/auth/actions";
import {useAuthDispatch} from "../../contexts/auth/context";

export const useLogoutFormState = (postSubmit?:()=>void) => {
    const dispatch = useAuthDispatch();

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        logout(dispatch).then(r=>{});
        if(typeof postSubmit !== "undefined") postSubmit()
    }

    return {
        handleSubmit
    }
}