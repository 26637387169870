import {Col, Container, Row, Spinner} from "reactstrap";

export const Loading = () => {
    return (
        <Container fluid className={"screen-height"}>
            <Row className={"h-100"}>
                <Col className={"text-center h-100 d-flex justify-content-center align-items-center"}>
                    <Spinner children={''}/>
                </Col>
            </Row>
        </Container>
    )
}