import {useLoginFormState} from "../../../states/account/loginform.state";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";

export const LoginForm = ({toggle}:{toggle?:()=>void}) => {
    const {
        usernameChange,
        passwordChange,
        loading,
        error_message,
        handleSubmit
    } = useLoginFormState(toggle)

    return (
        <Form>
            <FormGroup>
                <Label for={"username"}>Username</Label>
                <Input type={"text"} name={"username"} id={"username"} onChange={usernameChange}/>
            </FormGroup>
            <FormGroup>
                <Label for={"password"}>Password</Label>
                <Input type={"password"} name={"password"} id={"password"} onChange={passwordChange}/>
            </FormGroup>
            <FormGroup>
                {error_message}
            </FormGroup>
            <Button onClick={handleSubmit} color={"primary"} block disabled={loading}>Submit</Button>
        </Form>
    )
}