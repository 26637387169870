import {IRoute} from "../interfaces/route.interface";
import {ItemsRoutes} from "./items.routes";
import {UnauthorizedPage} from "../pages/unauthorized.page";
import {ErrorsRoutes} from "./errors.routes";

const baseRoutes:IRoute[] = [
    {
        path: '/unauthorized',
        component: UnauthorizedPage
    },
    {
        path: '/login',
        component: UnauthorizedPage
    }
];
baseRoutes.push(...ItemsRoutes);


baseRoutes.push(...ErrorsRoutes);
export default baseRoutes;