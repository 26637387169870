import {ISingleItem} from "../../interfaces/item.interface";
import {Reducer} from "react";
import {IItemEntry} from "../../interfaces/itementry.interface";

export type itemEditActionTypes =
    {type: 'UPDATE_ITEM', payload: {key:string, value:any}} |
    {type: 'UPDATE_META_VALUE', payload:{entry:IItemEntry, input_name:string, value:any}} |
    {type: "RESET", payload:{}}

export const initialState:ISingleItem = {
    added_by: {
        id: -1,
        username: 'Not selected'
    },
    available_on_store: false,
    brand: {
        id: -1,
        name: "Please Select..."
    },
    for_sale: false,
    id: 0,
    is_queued: false,
    is_uploaded: false,
    is_vat_applicable: false,
    item_type: {
        id: -1,
        name: "Please Select..."
    },
    name: "",
    sale_price: 0,
    upload: ""
}

export const ItemEditReducer:Reducer<ISingleItem, itemEditActionTypes> = (currentState, action) => {
    switch (action.type) {
        case "RESET":
            return {
                ...initialState
            }
        case "UPDATE_ITEM":
            return {
                ...currentState,
                [action.payload.key]: action.payload.value
            }
        case "UPDATE_META_VALUE":
            if(typeof currentState.item_entries === "undefined") {
                currentState.item_entries = [{...action.payload.entry}]
            }
            const entries = currentState.item_entries.map(entry => {
                if(entry.form_name === action.payload.entry.slug) {
                    entry.data.values[action.payload.input_name] = action.payload.value
                }
                return entry;
            })
            return {
                ...currentState,
                item_entries: entries
            }
    }
}