import {IItemTableColumn} from "../../interfaces/itemtablecolumn";
import {SyntheticEvent, useCallback, useEffect, useState} from "react";
import Api from '../../modules/api.module';
import {useAuthState} from "../../contexts/auth/context";
import {useItemFilterContext, useItemFilterDispatchContext} from "../../contexts/filter/filter.context";
import {filterMeta, filterName} from "../../contexts/filter/filter.actions";

export const useColumnFilter = () => {
    const [columns, setColumns] = useState<IItemTableColumn[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const userDetails = useAuthState();
    const dispatch = useItemFilterDispatchContext();
    const itemFilterContext = useItemFilterContext();

    const changeName = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        filterName(dispatch,target.value);
    }
    const changeMeta = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        filterMeta(dispatch, {key:target.name, value:target.value});
    }
    const getForms = useCallback(() => {
        setLoading(true);
        if(typeof(itemFilterContext.item_type)==="undefined" || itemFilterContext.item_type?.id === -1) {
            setColumns([]);
            itemFilterContext.meta_keys = {};
            setLoading(false)
            return
        }
        let collectedColumns:IItemTableColumn[] = [];
        Api
            .get('forms/get_forms', {
                params: {
                    item_type: itemFilterContext.item_type?.id,
                    brand: (itemFilterContext.brand?.id === -1)?null:itemFilterContext.brand?.id
                },
                headers: {
                    'Authorization': `token ${userDetails.auth_token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then(async res => {
                for await (let form of res.data) {
                    await getFormInputs(form.slug, collectedColumns)
                }
            })
            .catch(err=>{
            })
            .finally(()=>{
                setColumns(collectedColumns);
                setLoading(false);
            })
        // console.log('END')
    }, [itemFilterContext.brand, itemFilterContext.item_type, userDetails.auth_token]);
    const getFormInputs = (formSlug:string, collectedColumns:IItemTableColumn[]) => {
        setLoading(true);
        return Api
            .options('fobi/fobi-form-entry/'+formSlug+'/')
            .then(res=>{
                if(res.data.actions && res.data.actions.PUT) {
                    Object.entries(res.data.actions.PUT).forEach(([key,value]:[key:any,value:any])=>{
                        collectedColumns.push({text:key, input_name:value.label});
                    })
                }
            })
            .catch(err=>{})
            .finally(()=>{})
    }

    useEffect(()=>{
        getForms();
    },[itemFilterContext.brand?.id, itemFilterContext.item_type?.id, getForms]);

    return {
        columns,
        loading,
        changeName,
        changeMeta
    }
}