import React from "react";
import {IItemEntry} from "../../interfaces/itementry.interface";

export function changeItemValue(dispatch:React.Dispatch<any>, payload:{key:string, value:any}) {
    try {
        dispatch({type:'UPDATE_ITEM', payload:payload});
    } catch (e) {}
}

export function changeItemMetaValue(dispatch:React.Dispatch<any>, payload:{entry:IItemEntry, input_name:string, value:any}) {
    try {
        dispatch({type:'UPDATE_META_VALUE', payload:payload})
    } catch (e) {}
}

export function resetCurrentItem(dispatch:React.Dispatch<any>) {
    try {
        dispatch({type:'RESET'})
    } catch (e) {}
}