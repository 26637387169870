import {ISingleItem} from "../../../interfaces/item.interface";
import {Card, CardBody, CardText, CardTitle, Col, Container, Row} from "reactstrap";
import { Price } from "../atoms/price.atom";

export const PrimaryDetails = ({item}:{item:ISingleItem}) => {
    return (
        <Card>
            <Container>
                <Row xs={12}>
                    <Col xs={12} md={4}>
                        <img className={"img-fluid mx-auto"} src={item.upload} alt={item.name}/>
                    </Col>
                    <Col>
                        <CardBody>
                            <CardTitle tag={"h5"}>{item.name}</CardTitle>
                            <CardText className={"text-muted"}>Reference: {item.reference}</CardText>
                            <CardText className={"text-right"}><Price value={item.sale_price} incTax={item.is_vat_applicable}/></CardText>
                            <CardText>{item.item_type.name} | {item.brand.name}</CardText>
                        </CardBody>
                    </Col>
                </Row>
            </Container>
        </Card>
    )
}