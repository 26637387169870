import {IItem} from "../../interfaces/item.interface";
import {SyntheticEvent} from "react";
import Api from '../../modules/api.module';
import {useAuthState} from "../../contexts/auth/context";
import {useHistory} from "react-router-dom";
import {useWooCommerceIcon} from "./woocommerceicon.state";

export const useDeleteButton = ({item}:{item:IItem}) => {
    const userDetails = useAuthState();
    const history = useHistory();
    const {
        removeFromWoo
    } = useWooCommerceIcon(item);

    const handleClick = (e:SyntheticEvent) => {
        if(!item.id) return
        removeFromWoo();
        const formData = new FormData();
        formData.append('item_id', item.id.toString());
        Api
            .delete('get_items/delete_item', {
                headers: {
                    'Authorization': `token ${userDetails.auth_token}`,
                    'content-type': 'multipart/form-data'
                },
                data: formData
            })
            .then(res => {
                history.push('');
            })
            .catch(err => {})
    }

    return {
        handleClick
    }
}