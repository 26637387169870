import {useLogoutFormState} from "../../../states/account/logoutform.state";
import {Button} from "reactstrap";

export const LogoutButton = () => {
    const {
        handleSubmit
    } = useLogoutFormState();

    return (
        <>
            <Button onClick={handleSubmit} color={"danger"} block>Log out</Button>
        </>
    )
}