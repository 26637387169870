import React from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import baseRoutes from "./routes/base.routes";
import {ProtectedRoute} from "./components/routes/protected.route";

function App() {
  function RouteWithSubRoutes(route:any) {
    if(route.is_private) {
      return <ProtectedRoute authentication_path={'/login'} {...route}/>
    }
    return <Route
        path={route.path}
        render={props=>(
            <route.component {...props} routes={route.routes}/>
        )}
    />
  }

  return (
    <Switch>
      {baseRoutes.map((route:any, i:number) => (
          <RouteWithSubRoutes {...route} key={i}/>
      ))}
    </Switch>
  );
}

export default App;
