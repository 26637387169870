import Api from '../../modules/api.module';
import React from "react";
import {authActionTypes} from "./reducer";

export async function loginUser(dispatch:React.Dispatch<authActionTypes>, loginPayload:{username:string, password:string}) {
    try {
        dispatch({type:'REQUEST_LOGIN', payload: null});
        return await Api
            .post('authenticate/', loginPayload)
            .then(res => {
                const data = res.data;
                if(data.user) {
                    dispatch({type:'LOGIN_SUCCESS', payload:data});
                    localStorage.setItem('currentUser', JSON.stringify(data.user));
                    localStorage.setItem('token', JSON.stringify(data.auth_token));
                    localStorage.setItem('loginTimer', new Date().toISOString());
                    return data;
                } else {
                    dispatch({type:'LOGIN_ERROR', payload: 'Login error'})
                }
            })
            .catch(err => {
                dispatch({type:'LOGIN_ERROR', payload:'Please check your username and password'})
            })
    } catch(e) {
        dispatch({type:'LOGIN_ERROR', payload:'Please check your username and password'})
    }
}

export async function loginExpiryCheck(dispatch:React.Dispatch<authActionTypes>) {
    if(localStorage.getItem('currentUser') === null) return;
    const timer = localStorage.getItem('loginTimer');
    if(timer === null) {
        return logout(dispatch)
    }
    const datedTime = new Date(timer);
    const thisTime = new Date();
    const interval = 900000; //15 Minute
    if(((thisTime.getTime()-datedTime.getTime())/1000)/60 > interval) {
        return logout(dispatch)
    }
    localStorage.setItem('loginTimer', thisTime.toISOString())
}

export async function logout(dispatch:React.Dispatch<authActionTypes>) {
    dispatch({type:'LOGOUT', payload: null});
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('loginTimer');
}