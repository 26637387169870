import {useAdditionalForms} from "../../../states/items/additionalforms.state";
import {Loading} from "../../common/molecules/loading.molecule";
import {AdditionalForm} from "./additionalform.organism";

export const AdditionalForms = ({submissionSignal, validationSignal}:{submissionSignal?:Date, validationSignal?:Date}) => {
    const {
        additionalForms,
        loading
    } = useAdditionalForms({submissionSignal: submissionSignal, validationSignal: validationSignal});

    if (loading) {
        return <Loading/>
    }

    return (
        <>
            <h1>Forms</h1>
            {additionalForms.map(form => {
                return <AdditionalForm key={form.id} form={form} validationSignal={validationSignal}
                                       submissionSignal={submissionSignal}/>
            })}
        </>
    )
}