import {SyntheticEvent, useCallback, useEffect, useState} from "react";
import {IBrand, IItem, IItemType} from "../../interfaces/item.interface";
import Api from "../../modules/api.module";
import {useItemFilterContext, useItemFilterDispatchContext} from "../../contexts/filter/filter.context";
import {filterBrand, filterType} from "../../contexts/filter/filter.actions";

const initialType:IItemType = {id:-1, name:'Please Select'};
const initialBrand:IBrand = {id:-1, name:'Please Select'};

export const useTypeBrandFilter = ({loading=true, item}:{loading:boolean, item?:IItem}) => {
    const initType:IItemType = {id:-1, name:'Please Select'};
    const initBrand:IBrand = {id:-1, name:'Please Select'};
    const [types, setTypes] = useState<IItemType[]>([initialType]);
    const [brands, setBrands] = useState<IBrand[]>([initialBrand]);
    const [selectedType, setSelectedType] = useState<IItemType>((item && item.item_type)?item.item_type:initialType);
    const [selectedBrand, setSelectedBrand] = useState<IBrand>((item && item.brand)?item.brand:initialBrand);
    const [thisLoading, setLoading] = useState<boolean>(loading)
    // const currentFilter = useItemFilterContext();
    const dispatch = useItemFilterDispatchContext();

    const changeType = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement
        const foundType = types.find(type=>type.id===Number(target.value));
        setSelectedType(foundType?foundType:initType);
    }
    const changeBrand = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement
        const foundBrand = brands.find(brand=>brand.id===Number(target.value));
        setSelectedBrand(foundBrand?foundBrand:initBrand);
    }
    const getBrands = useCallback(() => {
        if(!loading) setLoading(true);
        Api
            .get('get_brands')
            .then(res=>{
                setBrands([initialBrand, ...res.data])
            })
            .catch(err=>{})
        setLoading(false);
    }, [loading]);
    const getTypes = useCallback(() => {
        setLoading(true);
        Api
            .get('get_item_types')
            .then(res=>{
                setTypes([initialType, ...res.data])
            })
            .catch(err=>{})
        setLoading(false);
    }, []);

    useEffect(()=>{
        getBrands();
        getTypes();
    }, [getBrands, getTypes])
    useEffect(()=>{
        if(!item) return
        setSelectedType(item.item_type);
        setSelectedBrand(item.brand);
    }, [item, dispatch])
    useEffect(()=>{
        filterType(dispatch, selectedType);
    },[selectedType, dispatch])
    useEffect(()=>{
        filterBrand(dispatch, selectedBrand);
    }, [selectedBrand, dispatch])

    return {
        types,
        brands,
        selectedType,
        selectedBrand,
        loading: (loading)?loading:thisLoading,
        changeType,
        changeBrand
    }
}