import {SyntheticEvent} from "react";

type myType = {
    useGenerated: boolean
    changeGenerated: (e:SyntheticEvent)=>void,
    disabled: boolean
}

export const GeneratedNameButton = ({useGenerated = false, changeGenerated = (e:SyntheticEvent)=>{}, disabled = false}:myType) => {
    return (
        <div className={"form-check form-switch"}>
            <input className={"form-check-input"} type={"checkbox"} onChange={changeGenerated} checked={useGenerated} disabled={disabled}/>
            <label className={disabled?'text-muted':''}>Auto-generate name {disabled && '(No fields to generate name)'}</label>
        </div>
    )
}