import {ISingleItem} from "../../interfaces/item.interface";
import {initialState, itemEditActionTypes, ItemEditReducer} from "./item.reducer";
import * as React from 'react';
import {useReducer} from "react";

const ItemEditContext = React.createContext<ISingleItem>(initialState)
const ItemEditDispatchContext = React.createContext<any>(null);

export function useItemEditContext() {
    return React.useContext(ItemEditContext);
}

export function useItemEditDispatchContext() {
    return React.useContext(ItemEditDispatchContext);
}

export const ItemEditProvider = ({children, item}:{children:any, item:ISingleItem}) => {
    const [state, dispatch]: [ISingleItem, React.Dispatch<itemEditActionTypes>] = useReducer(ItemEditReducer, item);
    return (
        <ItemEditContext.Provider value={state}>
            <ItemEditDispatchContext.Provider value={dispatch}>
                {children}
            </ItemEditDispatchContext.Provider>
        </ItemEditContext.Provider>
    )
}