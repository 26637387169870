import axios from 'axios';

export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
})

export const authHeaders = (token:string) => {
    return {
        headers: {
            'Authorization': `token ${token}`,
            'content-type': 'multipart/form-data'
        }
    }
}