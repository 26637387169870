import {DefaultTemplate} from "../components/templates/default.template";
import {ItemFilterForm} from "../components/items/molecules/itemfilterform.molecule";
import {ItemTable} from "../components/items/organisms/itemtable.organisms";
import {ItemFilterProvider} from "../contexts/filter/filter.context";

export const ItemsPage = () => {
    const content = (
        <>
            <ItemFilterForm loading={false}/>
            <ItemTable />
        </>
    );
    return (
        <ItemFilterProvider>
            <DefaultTemplate content={content} footer={''}/>
        </ItemFilterProvider>
    )
}