import {useItemEdit} from "../../../states/items/itemedit.state";
import {
    Alert,
    Button,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from "reactstrap";
import {ItemSelectField} from "../molecules/itemSelectField.molecule";
import {NameInput} from "../molecules/nameinput.molecule";
import {AdditionalForms} from "./additionalforms.organism";

export const ItemEditForm = () => {
    const {
        brands,
        types,
        changeType,
        changeBrand,
        item,
        changeValue,
        formErrors,
        submit,
        submitSignal,
        validationSignal,
        changeImage,
        submissionLoading,
        saveResult,
        reset,
        submitAddNew
    } = useItemEdit();

    return (
        <>
            <Form>
                <h1>Item Details</h1>
                <NameInput/>
                <ItemSelectField
                    labelName={'Brand:'}
                    inputName={'brand'}
                    changeFunction={changeBrand}
                    currentValue={item.brand.id}
                    options={brands}
                />
                <ItemSelectField
                    labelName={'Item Type:'}
                    inputName={'item_type'}
                    changeFunction={changeType}
                    currentValue={item.item_type.id}
                    options={types}
                />
                <FormGroup>
                    <Label>Price</Label>
                    <InputGroup>
                        <InputGroupAddon addonType={"prepend"}>
                            <InputGroupText>£</InputGroupText>
                        </InputGroupAddon>
                        <Input
                            type={"text"}
                            name={"sale_price"}
                            id={"salePrice"}
                            value={item.sale_price}
                            onChange={changeValue}
                            invalid={formErrors.hasOwnProperty('sale_price')}
                        />
                    </InputGroup>
                    {formErrors["sale_price"] && <FormFeedback>{formErrors["sale_price"]}</FormFeedback>}
                </FormGroup>
                <FormGroup check>
                    <Label for={"vatApplicable"} check>
                        <Input type={"checkbox"} name={"is_vat_applicable"} id={"vatApplicable"} onChange={changeValue} checked={item.is_vat_applicable}/>
                        Is VAT applicable
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label for={"forSale"} check>
                        <Input type={"checkbox"} name={"for_sale"} id={"forSale"} onChange={changeValue} checked={item.for_sale}/>
                        Is for sale
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check for={"availableOnStore"}>
                        <Input type={"checkbox"} name={"available_on_store"} onChange={changeValue} checked={item.available_on_store}/>
                        Available on store
                    </Label>
                </FormGroup>
                <FormGroup>
                    <Label for={"itemFile"}>Upload</Label>
                    <Input type={"file"} name={"upload"} id={"itemFile"} onChange={changeImage}/>
                    <FormText color={"muted"}>
                        {item.upload && <Button color={"link"} size={"sm"} href={item.upload} target={"_blank"}>Current upload</Button>}
                    </FormText>
                </FormGroup>
                {item && <AdditionalForms submissionSignal={submitSignal} validationSignal={validationSignal}/>}
                <FormGroup className={"text-right my-4"}>
                    <Button className={"mx-2"} onClick={submit} color={"primary"} disabled={submissionLoading}>Save</Button>
                    <Button className={"mx-2"} onClick={submitAddNew} color={"info"} disabled={submissionLoading}>Save and Add New</Button>
                    <Button className={"mx-2"} onClick={reset} color={"danger"}>Reset</Button>
                </FormGroup>
                {saveResult && <Alert color={"success"}>Item saved successfully</Alert>}
            </Form>
        </>
    )
}