import {ItemsPage} from "../pages/items.page";
import {ItemPage} from "../pages/item.page";
import {IRoute} from "../interfaces/route.interface";
import {ItemEdit} from "../pages/itemedit.page";
import {ItemNew} from "../pages/itemnew.page";

export const ItemsRoutes:IRoute[] = [
    {
        path: '/item/new',
        component: ItemNew,
        is_private: true
    },
    {
        path: '/item/edit/:id',
        component: ItemEdit,
        is_private: true
    },
    {
        path: '/item/:id',
        component: ItemPage,
        is_private: true
    },
    {
        path: '/',
        component: ItemsPage,
        is_private: true,
        exact: true
    },
]
