import {IBrand, IItemType} from "../../interfaces/item.interface";
import {Reducer} from "react";
import {IItemFilter} from "../../interfaces/searchfilter.interface";

export type itemFilterActionTypes =
    {type: 'CHANGE_BRAND', payload: IBrand} |
    {type: 'CHANGE_TYPE', payload: IItemType} |
    {type: 'SET_NAME', payload: string} |
    {type: 'CHANGE_META', payload:{key:string, value:string}}

export const initialState:IItemFilter = {}

export const ItemFilterReducer:Reducer<IItemFilter, itemFilterActionTypes> = (initialState, action) => {
    const changedValue = new Date();
    switch (action.type) {
        case "CHANGE_TYPE":
            return {
                ...initialState,
                item_type: action.payload,
                changed: changedValue.toISOString()
            };
        case "CHANGE_BRAND":
            return {
                ...initialState,
                brand: action.payload,
                changed: changedValue.toISOString()
            };
        case "SET_NAME":
            return {
                ...initialState,
                name: action.payload,
                changed: changedValue.toISOString()
            };
        case "CHANGE_META":
            const meta = {...initialState.meta_keys, [action.payload.key]:action.payload.value};
            Object.entries(meta).forEach(entry => {
                if(entry[1] === "") {
                    delete meta[entry[0]]
                }
                return;
            })
            return {
                ...initialState,
                meta_keys: meta,
                changed: changedValue.toISOString()
            }
    }
}