import {IItemFilter} from "../../interfaces/searchfilter.interface";
import * as React from "react";
import {useReducer} from "react";
import {initialState, itemFilterActionTypes, ItemFilterReducer} from "./filter.reducer";

const ItemFilterContext = React.createContext<IItemFilter>({});
const ItemFilterDispatchContext = React.createContext<any>(null);

export function useItemFilterContext() {
    return React.useContext(ItemFilterContext);
}

export function useItemFilterDispatchContext() {
    return React.useContext(ItemFilterDispatchContext);
}

export const ItemFilterProvider = ({children}:{children:any}) => {
    const [state, dispatch]: [IItemFilter, React.Dispatch<itemFilterActionTypes>] = useReducer(ItemFilterReducer, initialState);
    return (
        <ItemFilterContext.Provider value={state}>
            <ItemFilterDispatchContext.Provider value={dispatch}>
                {children}
            </ItemFilterDispatchContext.Provider>
        </ItemFilterContext.Provider>
    )
}