import {IAuth} from "../../interfaces/auth.interface";
import {ILoggedInUser} from "../../interfaces/user.interface";
import {Reducer} from "react";

export type authActionTypes =
    {type: 'REQUEST_LOGIN', payload: null} |
    {type: 'LOGIN_SUCCESS', payload: { user:ILoggedInUser, auth_token:string|undefined }} |
    {type: 'LOGOUT', payload: null} |
    {type: 'LOGIN_ERROR', payload: string};

//@ts-ignore
let user:ILoggedInUser|undefined = localStorage.getItem('currentUser')?JSON.parse(localStorage.getItem('currentUser')):undefined;
//@ts-ignore
let token:string|undefined = localStorage.getItem('token')?JSON.parse(localStorage.getItem('token')):undefined;

export const initialState:IAuth = {
    loading: false,
    user: user,
    auth_token: token
}

export const AuthReducer:Reducer<IAuth, authActionTypes> = (initialState, action) => {
    switch (action.type) {
        case 'REQUEST_LOGIN':
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                user: action.payload.user,
                auth_token: action.payload.auth_token,
                loading: false,
                error_message: ''
            };
        case "LOGOUT":
            return {
                ...initialState,
                user: undefined,
                auth_token: undefined
            }
        case "LOGIN_ERROR":
            return {
            ...initialState,
            loading: false,
            error_message: action.payload
        };
    }
}