import {ItemFull} from "../components/items/organisms/itemfull.organism";
import {DefaultTemplate} from "../components/templates/default.template";

export const ItemPage = (props:any) => {
    const content = (
        <>
            <ItemFull id={props.match.params.id}/>
        </>
    )

    return (
        <DefaultTemplate content={content} footer={''}/>
    )
}