import {IItem} from "../../../interfaces/item.interface";
import {WooIcon} from "../atoms/wooicon.atom";
import {IItemTableColumn} from "../../../interfaces/itemtablecolumn";

export const ItemRow = ({item, columns}:{item:IItem, columns:IItemTableColumn[]}) => {
    const color = item.available_on_store?'':'table-danger';

    return (
        <tr key={item.id} className={color}>
            <td>{item.id}</td>
            <td><WooIcon item={item}/></td>
            <td><a href={"/item/"+item.id} className={"text-link"}>{item.name}</a></td>
            <td>{item.brand.name}</td>
            <td>{item.item_type.name}</td>
            {columns.map(column => {
                let res = item.item_entries?.find(form_entry => form_entry.data.values[column.text])
                if(res) {
                    return <td key={column.text}>{res.data.values[column.text]}</td>
                } else {
                    return <td key={column.text}></td>
                }
            })}
        </tr>
    )
}