import {itemFilterActionTypes} from "./filter.reducer";
import {IBrand, IItemType} from "../../interfaces/item.interface";
import React from "react";

export function filterBrand(dispatch:React.Dispatch<itemFilterActionTypes>, brandPayload:IBrand) {
    try {
        dispatch({type:'CHANGE_BRAND', payload:brandPayload})
    } catch (e) {

    }
}

export function filterType(dispatch:React.Dispatch<itemFilterActionTypes>, typePayload:IItemType) {
    try {
        dispatch({type:'CHANGE_TYPE', payload:typePayload})
    } catch (e) {

    }
}

export function filterName(dispatch:React.Dispatch<itemFilterActionTypes>, name:string) {
    try {
        dispatch({type:'SET_NAME', payload:name})
    } catch (e) {
        
    }
}

export function filterMeta(dispatch:React.Dispatch<itemFilterActionTypes>, metaPayload:{key:string, value:string}) {
    try {
        if(typeof metaPayload.value === "undefined") return
        dispatch({type:'CHANGE_META', payload:metaPayload})
    } catch (e) {
        
    }
}