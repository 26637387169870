import {IAdditionalFormInput} from "../../../interfaces/additionalForm.interface";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {SyntheticEvent} from "react";

export const AdditionalFormField = ({field, changeFunc}:{field:IAdditionalFormInput, changeFunc:(e:SyntheticEvent)=>void}) => {
    let input: JSX.Element;
    const showError:boolean = (typeof (field.error) !== "undefined" && field.error.length > 0);

    switch (field.type) {
        case "choice":
            input = (
                <Input type={"select"} name={field.name} required={field.required} onChange={changeFunc} value={field.value} invalid={showError}>
                    <option value={-1}>Please select...</option>
                    {field.choices && field.choices.map((option, index) => {
                        return <option key={field.name+index} value={option.value}>{option.display_name}</option>
                    })}
                </Input>
            )
            break;
        default:
            if(!field.max_length) {
                input  = <Input type={"textarea"} onChange={changeFunc} name={field.name} value={field.value} required={field.required} invalid={showError}/>
                break;
            }
            input = <Input type={"text"} onChange={changeFunc} name={field.name} value={field.value} required={field.required} invalid={showError}/>
            break;
    }

    return (
        <FormGroup>
            <Label>{field.label}{field.required && <>*</>}</Label>
            {input}
            {field.error && <FormFeedback>{field.error}</FormFeedback>}
        </FormGroup>
    )
}