import {useModal} from "../../../states/components/modal.state";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {ILoggedInUser} from "../../../interfaces/user.interface";
import {LogoutButton} from "../atoms/logoutbutton.atom";
import {LoginForm} from "../molecules/loginform.molecule";

export const LoginModal = ({user}:{user?:ILoggedInUser}) => {
    const {
        isOpen,
        toggle
    } = useModal();

    const textToShow = user?'Log out':'Log in';

    return (
        <>
            <Button onClick={toggle} color={"link"} className={"text-left text-white p-0 text-decoration-none align-bottom"} block>
                {textToShow}
            </Button>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {textToShow}
                </ModalHeader>
                <ModalBody>
                    {user ?
                        <><p>Are you sure?</p><LogoutButton/></> :
                        <LoginForm toggle={toggle}/>
                    }
                </ModalBody>
            </Modal>
        </>
    )
}