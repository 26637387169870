import {Col, Container, Input, Row, Table} from "reactstrap";
import {useColumnFilter} from "../../../states/items/columnfilter.state";
import {IItemTableColumn} from "../../../interfaces/itemtablecolumn";
import { ItemRows } from "./itemrows.organism";
import {Loading} from "../../common/molecules/loading.molecule";
import {useItemCSV} from "../../../states/downloads/itemcsv.state";
import {GenerateCSVButton} from "../../csv/molecules/generatecsvbutton.molecule";

export const ItemTable = () => {
    const {
        columns,
        loading,
        changeName,
        changeMeta
    } = useColumnFilter();

    if(loading) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            <div className={"d-flex justify-content-end"}>
                <GenerateCSVButton tableId={'itemListingTable'}/>
            </div>
            <Table responsive striped id={"itemListingTable"}>
                <thead>
                <tr>
                    <th id={'id'}>#</th>
                    <th id={'status'} style={{width:"20px"}}></th>
                    <th id={'name'}>Name <Input onChange={changeName}/></th>
                    <th id={'brand'}>Brand</th>
                    <th id={'item_type'}>Item Type</th>
                    {columns.map((column:IItemTableColumn, index) => (
                        <th id={column.input_name} key={column.text}>{column.input_name}<Input name={column.text} value={column.value} onChange={changeMeta}/></th>
                    ))}
                </tr>
                </thead>
                <tbody>
                <ItemRows columns={columns}/>
                </tbody>
            </Table>
        </>
    )
}