import {SyntheticEvent, useCallback, useEffect, useState} from "react";
import {useItemEditContext, useItemEditDispatchContext} from "../../contexts/item/item.context";
import Api from "../../modules/api.module";
import {changeItemValue} from "../../contexts/item/item.actions";

export const useNameGeneration = () => {
    const itemContext = useItemEditContext();
    const dispatch = useItemEditDispatchContext();
    
    const [generatedName, setGeneratedName] = useState<string>('');
    const [manualName, setManualName] = useState<string>(itemContext.name)
    const [useGeneratedName, setUseGeneratedName] = useState<boolean>(false);
    const [nameGenerationFields, setNameGenerationFields] = useState<{[key:string]:string}>({});
    const [canGenerateName, setCanGenerateName] = useState<boolean>(false);

    const changeName = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        if(useGeneratedName) return;
        setManualName(target.value);
    }
    const changeGeneratingName = () => {
        setUseGeneratedName(!useGeneratedName);
    }
    const getNameGenerationFields = useCallback(() => {
        if(itemContext.item_type.id === -1) {
            setNameGenerationFields({});
            return
        }
        Api
            .get('forms/naming/get_naming_pattern',
                {
                    params: {
                        item_type: itemContext.item_type.id,
                        brand: (itemContext.brand.id === -1)?null:itemContext.brand.id
                    }
                })
            .then(res => {
                if(!res.data.naming_pattern || res.data.naming_pattern.length === 0) {
                    setNameGenerationFields({});
                    return
                }
                const strippedPattern = res.data.naming_pattern.replace('"','');
                const splitRes = strippedPattern.split(',');
                let retObj = {};
                splitRes.forEach((s:string) => {
                    const key = s.replace(/ /g, '');
                    retObj = {...retObj, [key]:''}
                })
                setNameGenerationFields(retObj);
            })
            .catch(err => {
                setNameGenerationFields({});
            })
            .finally(()=>{})
    },[itemContext.brand.id, itemContext.item_type.id])
    const generateName = useCallback(() => {
        let gName = '';
        Object.entries(nameGenerationFields).forEach(([fieldName, index]) => {
            if(itemContext.hasOwnProperty(fieldName)) {
                gName += fieldName + ' ';
            } else {
                itemContext.item_entries?.forEach(item_entry => {
                    if(item_entry.data.values[fieldName]) {
                        gName += item_entry.data.values[fieldName] + ' ';
                    }
                })
            }
        })
        setGeneratedName(gName);
    },[itemContext, nameGenerationFields])

    useEffect(()=>{
        getNameGenerationFields()
    },[itemContext.brand.id, itemContext.item_type.id]);
    useEffect(()=>{
        generateName()
        if(Object.keys(nameGenerationFields).length > 0) setCanGenerateName(true)
        else setCanGenerateName(false)
    },[nameGenerationFields, generateName])
    useEffect(()=>{
        if(useGeneratedName) {
            changeItemValue(dispatch, {key:'name', value:generatedName})
        } else {
            changeItemValue(dispatch, {key:'name', value:manualName})
        }
    },[useGeneratedName, generatedName, manualName])

    return {
        changeGeneratingName,
        useGeneratedName,
        changeName,
        item: itemContext,
        manualName,
        canGenerateName
    }
}