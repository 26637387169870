import {useItemCSV} from "../../../states/downloads/itemcsv.state";
import {Button} from "reactstrap";
import {CSVLink} from 'react-csv';

export const GenerateCSVButton = ({tableId}:{tableId: string}) => {
    const {
        scanTable,
        data
    } = useItemCSV({
        tableId: tableId
    });

    return (
        <>
            <Button onClick={scanTable}>Generate CSV</Button>
            {data.length > 0 && <CSVLink className={"btn btn-success"} data={data}>Download CSV</CSVLink>}
            {data.length < 1 && <Button disabled={true}>Nothing to download</Button>}
        </>

    )
}