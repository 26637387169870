export const AdditionalFields = ({values, fields}:{values: {[key:string]:string }, fields: { [key:string]:string }}) => {
    return (
        <>
            {Object.entries(fields).map(([key, value]) => {
                return (values[key])?(
                        <tr key={key}>
                            <td>{value}</td>
                            <td>{values[key]}</td>
                        </tr>
                    ) : (
                        <></>
                    )
                }
            )}
        </>
    )
}