import {useCallback, useEffect, useState} from "react";
import {IAdditionalForm, IAdditionalFormInput} from "../../interfaces/additionalForm.interface";
import Api from "../../modules/api.module";
import {useAuthState} from "../../contexts/auth/context";
import {useItemEditContext} from "../../contexts/item/item.context";

export const useAdditionalForms = ({submissionSignal, validationSignal}:{submissionSignal?:Date, validationSignal?:Date}) => {
    const item = useItemEditContext();
    const [loading, setLoading] = useState<boolean>(true);
    const userDetails = useAuthState();
    const [additionalForms, setAdditionalForms] = useState<IAdditionalForm[]>([]);

    const updateForms = useCallback(() => {
        if(!item) return;
        setLoading(loading => true);
        if(item?.item_type.id < 0) {
            setLoading(loading => false);
            setAdditionalForms([]);
            return
        }
        Api
            .get('forms/get_forms',{
                params: {
                    item_type: item.item_type.id,
                    brand: (item.brand.id === -1)?null:item.brand.id
                },
                headers: {
                    'Authorization': `token ${userDetails.auth_token}`,
                    'content-type': 'multipart/form-data'
                }
            })
            .then(async res => {
                const forms:IAdditionalForm[] = [];
                for await (let form of res.data) {
                    forms.push({
                        id: form.id,
                        url: form.url,
                        slug: form.slug
                    })
                }
                setAdditionalForms(forms);
                setLoading(loading => false);
            })
            .catch(err => {
                setLoading(loading => false);
            })
    }, [item.item_type, item.brand, userDetails.auth_token]);

    useEffect(()=>{
        updateForms();
    },[item.item_type, item.brand, updateForms]);

    return {
        loading,
        additionalForms
    }
}