import {DefaultTemplate} from "../components/templates/default.template";

export const UnauthorizedPage = () => {
    const content = (
        <>
            <h1>You must login to view this page.</h1>
        </>
    )
    return (
        <DefaultTemplate content={content} footer={''}/>
    )
}