import {useEffect, useState} from "react";

export const useItemCSV = ({tableId}:{tableId:string}) => {
    const [data, setData] = useState<string[][]>([]);

    const scanTable = () => {
        const collectedData = [];
        const table:HTMLTableElement | null = document.querySelector('#'+tableId);
        if(!table || !table.tHead) return;
        if(table.tHead && table.tHead.rows) {
            const columns: Element[] = Array.from(table.tHead.rows[0].cells);
            const header = columns.map(col => {
                return col.id;
            })
            collectedData.push(header);
        }
        if(table.tBodies[0].rows) {
            const rows: HTMLTableRowElement[] = Array.from(table.tBodies[0].rows);
            rows.forEach(row => {
                // console.log(row);
                if(!row.cells) return;
                const cells: Element[] = Array.from(row.cells);
                const collectedRow = cells.map(cell => {
                    return cell.textContent;
                })
                collectedData.push(collectedRow)
            })
        }
        setData(collectedData);
    }

    return {
        scanTable,
        data
    }
}