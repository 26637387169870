import * as React from 'react';
import {useEffect, useReducer} from "react";
import {authActionTypes, AuthReducer, initialState} from "./reducer";
import {loginExpiryCheck} from "./actions";
import {IAuth} from "../../interfaces/auth.interface";

//@ts-ignore
const AuthStateContext = React.createContext<IAuth>(null);
const AuthDispatchContext = React.createContext<any>(null);

export function useAuthState() {
    const context = React.useContext(AuthStateContext);
    if(context===undefined) throw new Error("useAuthState requires AuthStateContext")
    return context
}

export function useAuthDispatch() {
    const context = React.useContext(AuthDispatchContext);
    if(context===undefined) throw new Error("useAuthDispatch requires AuthDispatchContext")
    return context
}

export const AuthProvider = ({children}:{children:any}) => {
    const [state, dispatch]: [IAuth, React.Dispatch<authActionTypes>] = useReducer(AuthReducer, initialState);
    useEffect(()=>{
        loginExpiryCheck(dispatch).then(r=>{})
    }, [])
    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}