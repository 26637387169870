import {useTypeBrandFilter} from "../../../states/items/typebrandfilter.state";
import {Col, Container, Row} from "reactstrap";
import {ItemSelectField} from "./itemSelectField.molecule";

export const ItemFilterForm = ({loading}:{loading:boolean}) => {
    const {
        types,
        brands,
        selectedType,
        selectedBrand,
        changeType,
        changeBrand
    } = useTypeBrandFilter({loading: loading});

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <ItemSelectField
                            labelName={"Brand:"}
                            inputName={"brand"}
                            changeFunction={changeBrand}
                            currentValue={selectedBrand.id}
                            options={brands}/>
                    </Col>
                    <Col sm={12} md={6}>
                        <ItemSelectField
                            labelName={"Item Type:"}
                            inputName={"item_type"}
                            changeFunction={changeType}
                            currentValue={selectedType.id}
                            options={types}/>
                    </Col>
                </Row>
            </Container>
        </>
    )
}