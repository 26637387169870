import {useItemEditContext, useItemEditDispatchContext} from "../../contexts/item/item.context";
import {useTypeBrandFilter} from "./typebrandfilter.state";
import {SyntheticEvent, useEffect, useState} from "react";
import {changeItemValue, resetCurrentItem} from "../../contexts/item/item.actions";
import {useAuthState} from "../../contexts/auth/context";
import Api, {authHeaders} from "../../modules/api.module";

export const useItemEdit = () => {
    const itemContext = useItemEditContext();
    const dispatch = useItemEditDispatchContext();
    const {
        brands,
        types,
        changeType,
        changeBrand,
        selectedType,
        selectedBrand
    } = useTypeBrandFilter({loading:false, item:itemContext});
    const [fileUpload, setFileUpload] = useState<File>();
    const [formErrors, setFormErrors] = useState<{[key:string]:string}>({});
    const [submitSignal, setSubmitSignal] = useState<Date>();
    const [validationSignal, setValidationSignal] = useState<Date>();
    const userDetails = useAuthState();
    const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
    const [saveResult, setSaveResult] = useState<boolean>(false);
    const [resetAfterSubmit, setResetAfterSubmit] = useState<boolean>(false);

    const changeValue = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        if(itemContext.hasOwnProperty(target.name)) {
            changeItemValue(dispatch, {key:target.name, value:target.value});
        }
    }
    const changeImage = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        if(target.files && target.files[0]) setFileUpload(target.files[0])
    }
    const validate = () => {
        setFormErrors({});
        let passed = true;
        if(!itemContext) {
            setFormErrors(formErrors=>({...formErrors, name: 'No item found'}));
            return false;
        }
        if(!itemContext.name || itemContext.name.trim().length === 0) {
            setFormErrors(formErrors=>({...formErrors, name: 'You must set a name'}));
            passed = false;
        }
        if(!itemContext.item_type || itemContext.item_type.id === -1) {
            setFormErrors(formErrors => ({...formErrors, item_type: 'Please select an item type'}));
            passed = false;
        }
        if(!itemContext.brand || itemContext.brand.id === -1) {
            setFormErrors(formErrors => ({...formErrors, brand: 'Please select a brand'}));
            passed = false;
        }
        const numberRegex = /^\d+(\.\d{1,2})?$/
        if(!itemContext.sale_price || !numberRegex.test(itemContext.sale_price.toString())) {
            setFormErrors(formErrors => ({...formErrors, sale_price: 'Please enter a numeric value'}));
            passed = false;
        }
        setValidationSignal(new Date());
        return passed;
    }
    const submitAddNew = () => {
        setResetAfterSubmit(true);
        submit()
    }
    const reset = () => {
        resetCurrentItem(dispatch);
    }
    const submit = () => {
        if(!userDetails.auth_token) return
        setSubmissionLoading(true);
        if(!validate()) {
            setSubmissionLoading(false);
            return;
        }
        //to remove lint error
        if(fileUpload) {
            return
        }
        const formData = new FormData();
        Object.entries(itemContext).forEach(([key, value]) => {
            switch (key) {
                case "id":
                case "upload":
                case "added_by":
                case "item_entries":
                    break;
                case "item_type":
                case "brand":
                    formData.append(key, value.id)
                    break;
                default:
                    formData.append(key, value);
                    break;
            }
        })
        if(itemContext.id > 0) {
            formData.append('item_id', itemContext.id.toString())
        }
        formData.append('added_by', userDetails.auth_token);
        Api
            .post('get_items/', formData, authHeaders(userDetails.auth_token))
            .then(res => {
                const data = res.data;
                setSubmitSignal(new Date());
                if(!itemContext.id || itemContext.id < 1) {
                    changeItemValue(dispatch, {key:'id', value: data.id});
                }
                setSubmissionLoading(false);
                setSaveResult(true);
                if(resetAfterSubmit) {
                    reset();
                    setResetAfterSubmit(false);
                }
            })
            .catch(err => {
                setSubmissionLoading(false);
                setSaveResult(false);
            })
    }

    useEffect(()=>{
        changeItemValue(dispatch, {key: 'brand', value: selectedBrand})
    },[dispatch, selectedBrand])
    useEffect(()=>{
        changeItemValue(dispatch, {key:'item_type', value: selectedType})
    }, [dispatch, selectedType])

    return {
        brands,
        types,
        changeType,
        changeBrand,
        item: itemContext,
        changeValue,
        formErrors,
        changeImage,
        submit,
        submitSignal,
        validationSignal,
        submissionLoading,
        saveResult,
        submitAddNew,
        reset
    }
}