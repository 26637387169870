import {IItem} from "../../interfaces/item.interface";
import {useCallback, useEffect, useState} from "react";
import {BsArchive, BsClockFill, BsFillExclamationCircleFill, BsTagFill} from "react-icons/all";
import Api, {authHeaders} from '../../modules/api.module'
import {useAuthState} from "../../contexts/auth/context";

export const useWooCommerceIcon = (item:IItem) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [tooltipText, setToolTipText] = useState<string>('');
    const [icon, setIcon] = useState<any>(<></>)
    const [action, setAction] = useState<()=>void>(()=>{});
    const [thisItem, setThisItem] = useState<IItem>(item);
    const userDetails = useAuthState();

    const pushToWoo = useCallback(() => {
        if(!userDetails.auth_token) return
        setLoading(true)
        Api
            .get('woo/upload_single_item?id='+thisItem.id,
                authHeaders(userDetails.auth_token))
            .then(
                res=>{
                    const i = res.data;
                    setThisItem(thisItem => ({
                        ...thisItem,
                        is_queued: i.is_queued,
                        is_uploaded: i.is_uploaded
                    }))
                    setLoading(false);
                }
            )
            .catch(err => {
                setLoading(false)
            })
    }, [thisItem.id, userDetails.auth_token])
    const removeFromWoo = useCallback(() => {
        setLoading(true)
        const formData = new FormData();
        formData.append('item_id', thisItem.id.toString())
        Api
            .delete('woo/delete_from_woo',
                {
                    headers: {
                        'Authorization': `token ${userDetails.auth_token}`,
                        'content-type': 'multipart/form-data'
                    },
                    data: formData
                })
            .then(res => {
                setThisItem(thisItem => ({
                    ...thisItem,
                    is_queued:false,
                    is_uploaded: false
                }))
                setLoading(false)
                })
            .catch(
                err => {
                    setLoading(false)
                    return
                }
            )
    }, [thisItem.id, userDetails.auth_token])

    useEffect(()=>{
        if(!thisItem.available_on_store) {
            setToolTipText("Not available on store")
            setIcon(<BsFillExclamationCircleFill/>)
            setAction(()=>{})
            return
        }
        if(thisItem.is_queued) {
            setToolTipText("Queued for upload");
            setIcon(<BsClockFill/>)
            setAction(()=>{})
        } else if(thisItem.is_uploaded) {
            setToolTipText("Uploaded to WooCommerce");
            setIcon(<BsTagFill className={"text-success"}/>);
            setAction(()=>removeFromWoo)
        } else {
            setToolTipText("Not on WooCommerce");
            setIcon(<BsArchive/>)
            setAction(()=>pushToWoo)
        }
    },[thisItem, pushToWoo, removeFromWoo])

    return {
        tooltipText,
        icon,
        action,
        thisItem,
        woostateLoading: loading,
        removeFromWoo
    }
}