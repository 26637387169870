import {IAdditionalForm, IAdditionalFormInput} from "../../interfaces/additionalForm.interface";
import {useItemEditContext, useItemEditDispatchContext} from "../../contexts/item/item.context";
import {SyntheticEvent, useCallback, useEffect, useState} from "react";
import {changeItemMetaValue} from "../../contexts/item/item.actions";
import Api, {authHeaders} from "../../modules/api.module";
import {useAuthState} from "../../contexts/auth/context";

export const useAdditionalForm = ({form, submissionSignal, validationSignal}:{form:IAdditionalForm, submissionSignal?:Date, validationSignal?:Date}) => {
    const item = useItemEditContext();
    const dispatch = useItemEditDispatchContext();
    const [fields, setFields] = useState<IAdditionalFormInput[]>([]);
    const [validated, setValidated] = useState<boolean>(false);
    const userDetails = useAuthState();

    const changeValue = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        changeItemMetaValue(dispatch, {
            input_name: target.name,
            value: target.value,
            entry: {
                form_entry: form.id,
                form_name: form.slug,
                slug: form.slug,
                data: {
                    fields: {[target.name]: target.value},
                    values: {[target.name]: target.value}
                }
            }
        })

    }

    const getValues = (inputs:IAdditionalFormInput[]) => {
        const thisEntry = item.item_entries?.find(entry => {
            return entry.form_name === form.slug
        });
        if(typeof thisEntry === "undefined") return
        inputs.forEach(i => {
            if(thisEntry.data.values[i.name]) {
                i.value = thisEntry.data.values[i.name];
            }
        })
    }
    const getFields = useCallback(()=>{
        const collectedInputs:IAdditionalFormInput[] = [];
        Api
            .options('fobi/fobi-form-entry/'+form.slug+'/')
            .then(res=>{
                if(res.data.actions && res.data.actions.PUT) {
                    Object.entries(res.data.actions.PUT).forEach(([key, value]:[key:any, value:any])=>{
                        collectedInputs.push({
                            ...value,
                            name: key
                        })
                    })
                }
                getValues(collectedInputs);
                setFields(collectedInputs);
            })
            .catch(err=>{})
    }, [form]);

    const formValidationCallback = useCallback(()=>{
        if(!validationSignal) return;
        let passed = true;
        const updatedFields = fields.map(input => {
            input.error = "";
            if(input.required) {
                switch (input.type) {
                    case "string":
                        if(input.value < 1) {
                            input.error = "This field is required. ";
                            passed = false;
                        }
                        if(input.max_length && input.value > input.max_length) {
                            input.error += "Value too long, max allowed: "+input.max_length+", current: "+input.value.length;
                            passed = false;
                        }
                        break;
                    case "choice":
                        if(input.value === -1) {
                            input.error = "Please select an option. ";
                            passed = false;
                        }
                        break;
                }
            }
            return input;
        })
        setValidated(passed);
        setFields(updatedFields);
    },[validationSignal]);
    const submissionCallback = useCallback(()=>{
        if(!submissionSignal || !validated) return;
        if(!item.id || item.id  < 1) return;
        if(!userDetails.auth_token) return;
        //submit
        const formData = new FormData();
        fields.forEach(field => {
            if(typeof field.value === "undefined") return
            formData.append(field.name, field.value);
        })
        formData.append('item_id', item.id.toString());
        formData.append('form_entry_id', form.id.toString());
        Api
            .put('fobi/fobi-form-entry/'+form.slug+'/', formData, authHeaders(userDetails.auth_token))
            .then(res => {

            })
            .catch(err => {

            })
    },[submissionSignal]);

    useEffect(()=>{
        getFields()
    }, [form])
    useEffect(()=>{
        if(!validationSignal) return
        formValidationCallback()
    },[validationSignal]);
    useEffect(() => {
        if(!submissionSignal) return
        submissionCallback()
    }, [submissionSignal]);

    return {
        item,
        fields,
        changeValue
    }
}