import {useItemFull} from "../../../states/items/itemfull.state";
import {PrimaryDetails} from "../molecules/primarydetails.molecule";
import {Button, Container} from "reactstrap";
import {Loading} from "../../common/molecules/loading.molecule";
import {WooIcon} from "../atoms/wooicon.atom";
import {AdditionalData} from "../molecules/additionaldata.molecule";

export const ItemFull = ({id}:{id:number}) => {
    const {
        item,
        loading,
        userDetails
    } = useItemFull(id);


    if(loading) {
        return <Loading/>
    }

    if(!item) {
        return (
            <>
                No item found
            </>
        )
    }

    return (
        <>
            <PrimaryDetails item={item}/>
            <Container>
                {userDetails.user &&
                <>
                    <Button color={"link"} href={"/item/edit/"+item.id}>Edit</Button>|
                    <span className={"mx-3"}><WooIcon item={item}/></span>
                </>
                }
            </Container>
            {item.item_entries && <AdditionalData item_entries={item.item_entries}/>}
        </>
    )
}